<template>
  <button class="btn-add-question align-items-center justify-content-center" @click="addQuestion"><img src="~/mlearn/icons/home/add-question.svg" alt="Đặt câu hỏi" class="mr-1 mr-lg-2"> Đặt câu hỏi</button>
</template>

<script>
export default {
  methods: {
    addQuestion () {
      this.$store.dispatch('setShowQuestionDialog', true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-add-question{
    background: var(--danger);
    box-shadow: 0px 0px 8px rgba(255, 30, 30, 0.5);
    border-radius: 22px;
    font-weight: 500;
    font-size: 16px;
    color: var(--white);
    padding:10px 30px 10px 25px;
    width: 175px;
    text-align: center;
    @media (max-width:1366px) {
      padding:7px 10px;
      width: 175px;
    }
    @media (max-width:576px) {
      padding:5px 8px;
      width: 200px;
    }
    &:hover{
      box-shadow: 0px 0px 16px rgba(255, 30, 30, 0.5);
    }
  }
</style>
