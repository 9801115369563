<template>
  <div class="app-user-info-container">
    <div class="form-data align-items-center">
      <img :src="user.avatar || NoAvatar" alt="avatar" class="avatar rounded-circle"/>
      <div class="content child-info">
        <div class="user-name font-weight-bold text-break">{{user.fullName}}</div>
        <!-- <div class="score-info row">
          <span class="score-item col-lg-4 col-xl-3">
            <span class="score-label">{{ typeOfLevel === 'level' ? 'Level:' : 'Hạng:' }}</span>
            <span class="score">{{ level }}</span>
          </span>
          <span class="score-item col-lg-6">
            <span class="score-label">Điểm:</span>
            <span class="score">{{ score }}</span>
          </span>
        </div> -->
        <div class="qa-info d-flex align-items-center">
          <span class="q-info">
            <img src="~/mlearn/icons/home/number-question.svg" alt="Question">
            <span class="qa-info-label">Số câu đã hỏi:</span>
            <span class="qa-number text-danger">{{ question.total_question }}</span>
          </span>
          <!-- <span class="q-info">
            <img src="~/mlearn/icons/home/number-answer.svg" alt="Answer">
            <span class="qa-info-label">Số câu đã trả lời:</span>
            <span class="qa-number text-primary">{{ question.total_answer }}</span>
          </span> -->
        </div>
      </div>
    </div>
    <div class="qa-info row d-lg-none align-items-center">
      <!-- <span class="col-12 col-lg-6 q-info">
        <img src="~/mlearn/icons/home/number-question.svg" alt="Question">
        <span class="qa-info-label">Số câu đã hỏi:</span>
        <span class="qa-number text-danger">{{ question.total_question }}</span>
      </span> -->
      <!-- <span class="col-12 col-lg-6 q-info">
        <img src="~/mlearn/icons/home/number-answer.svg" alt="Answer">
        <span class="qa-info-label">Số câu đã trả lời:</span>
        <span class="qa-number text-primary">{{ question.total_answer }}</span>
      </span> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
import Resource from '~/common/api/resource'
const getInfoUser = new Resource('qa/statistic-by-user')
const getScoreUser = new Resource('score-management/score-info')

export default {
  data () {
    return {
      NoAvatar,
      question: {
        total_answer: 0,
        total_question: 0
      },
      level: 0,
      typeOfLevel: 'hạng',
      score: 0
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  created () {
    this.getInfo()
    this.getScore()
  },
  methods: {
    async getInfo () {
      const data = await getInfoUser.list({ user_id: this.user.uuid })
      if (data) {
        this.question = data.data
      }
    },
    async getScore () {
      const query = {
        userId: this.user.uuid,
        pointTypeCode: 'DEFAULT'
      }
      const { data } = await getScoreUser.list(query)
      if (data) {
        if (data.levelName.slice(0, 5).toLowerCase().includes('level')) {
          this.typeOfLevel = 'level'
          this.level = data.levelName.slice(6)
        } else if (data.levelName.slice(0, 4).toLowerCase().includes('hạng')) {
          this.typeOfLevel = 'hạng'
          this.level = data.levelName.slice(5)
        } else {
          this.level = data.levelName
        }
        this.score = data.currentPoint
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-user-info-container{
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
  border-radius: 8px;
  padding: 24px;
  @media (max-width:576px) {
    padding: 16px;
  }
  .avatar{
    width: 94px;
    height: 94px;
    @media (max-width:576px) {
      width: 64px;
      height: 64px;
    }
  }
  .content{
    margin-left: 16px;
    @media (max-width:576px) {
      margin-left: 12px;
    }
    .user-name {
      font-size: 18px;
      @media (max-width:576px) {
        font-size: 16px;
      }
    }
    .score-info {
      margin-top: 5px;
      margin-bottom: 5px;
      .score-item {
        .score-label {
          font-size: 16px;
          color: #6B6D70;
          margin-right: 5px;
          @media (max-width:576px) {}
        }
        .score {
          font-size: 16px;
          font-weight: 500;
          @media (max-width:576px) {}
        }
      }
    }
  }
  .qa-info {
    margin-top: 20px;
    @media (max-width:576px) {
      margin-top: 0px;
    }
    > [class*="col-"]{
      @media (max-width:576px) {
        margin-top: 10px;
      }
    }
    .q-info {
      background: #F5F5F5;
      border-radius: 8px;
      margin-right: 24px;
      padding:10px 12px;
      @media (max-width:576px) {
        margin-right: 16px;
        padding:8px 10px;
      }
      &:last-child{
        margin-right: 0;
      }
    }
    img {
      margin-right: 5px;
    }
    .qa-info-label {
      font-size: 14px;
    }
    .qa-number {
      font-weight: 500;
      font-size: 16px;
      @media (max-width:576px) {
        font-size: 14px;
      }
    }
  }
}
</style>
